<template>
  <div id="category-management-detail">
    <el-tabs tab-position="top" class="detail-language-options" type="card" stretch v-model="forLanguage">

      <el-tab-pane 
        v-for="el in getLangList()" 
        :key="el.code" 
        :name="el.code"
      >
        <el-row type="flex" align="middle" slot="label">
          <img class="custom-icon icon-lang mini" :src="getLangFlagImage(el.code)"/>
          <template 
            v-if="!isMobile || el.code === 'hi' || el.code === 'ta'"
          >{{ el.label }}</template>
        </el-row>
      </el-tab-pane>
      
      <el-form 
        ref="form" 
        :model="detail" 
        :rules="rules"
        label-position="top" 
        label-width="120px"
        :disabled="!fullAccess"
        @submit.native.prevent="handleSubmit"
      >

        <el-row v-if="!isAdding" type="flex" justify="end" align="middle" class="status-row">
          <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
          <el-select 
            v-model="detail.status"
            :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')">
            <el-option
              v-for="item in allSelectableStatusListFor(detail.status)"
              :key="item.value"
              :label="$t(item.value)"
              :value="item.value"
            />
          </el-select>
        </el-row>

        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">

          <el-col>
            <el-form-item class="is-required" :label="$t('CATEGORY_NAME')" prop="detailName">
              <el-input v-model="detailName">
                <template slot="prefix">
                  <i class="custom-icon icon-category mini"/>
                </template>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <page-footer
          :isLoading="isLoading"
          :handleBackTo="exit"
          :handleSubmit="fullAccess ? () => {} : null"
        />

      </el-form>

    </el-tabs>
  </div>
</template>


<script>
import { genericRecordsDetailMixin } from '@/utils/_mixins/generic-records-detail'
import { generalMixin } from "@/utils/general-mixin";
import { EventBus } from "@/utils/event-bus.js"
import PageFooter from "@/components/page-footer.vue"
import { mapGetters } from "vuex";

export default {
  name: 'CategoryManagementDetail',
  components: { PageFooter },
  mixins: [ genericRecordsDetailMixin, generalMixin ],
  data() {
    return {
      type: 'category',
      rules: {
        detailName: [
          { validator: this.checkName, trigger: 'blur' },
        ]
      },
    }
  },
  computed: {
    ...mapGetters("app", ["getLangList", "getLangFlagImage"]),
  },
  methods: {
    // REDIRECTION
    exit() { 
      this.goTo('/bo/category')
      EventBus.$emit("refresh-category")
    },
    checkURL(_, value, callback) {
      if (!value || value == "") {
        callback(new Error(this.$t("URL_CANNOT_EMPTY")));
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";

#category-management-detail {
  @include detail-language-options;
  
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  .status-row {
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
  .icon-lang {
    margin-right: 5px;
  }
}

.mobile {
  #category-management-detail {
    @include detail-language-options-mobile;
  }
}
</style>